.widget--sortable__item {
  display: flex;
  flex-direction: row;
  width: 100%;
  .sortable__item--container {
    flex: 1;
  }
  .sortable__item--drag {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    div {
      background-color: #FFF;
      padding: 4px 6px;
      border-radius: 4px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    }
  }
}