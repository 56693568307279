.cross_link__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #dfdfdf;
  margin-top: 12px;
  border-radius: 6px;
}

.cross-list__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 4px;
  user-select: none;
  .list__item-title {
    flex: 1;
    padding: 0 12px;
    a {
      font-weight: 600;
    }
  }
  & + .cross-list__item {
    border-top: 1px solid #dfdfdf;
  }
}
