.filter--article {
  background-color: #FFF;
  border-radius: 4px;
  padding: 8px;
  position: sticky;
  top: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  .filter--actions {
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    > button + button {
      margin-left: 12px;
    }
  }
  .filter--options {
    .filter--row + .filter--row {
      margin-top: 8px;
    }
  }
}


.app_view--filter {
  .ant-spin-nested-loading {
    position: sticky;
    top: 70px;
  }
}