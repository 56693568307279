.media-input {
  margin-bottom: 24px;
  .media--preview {
    max-width: 230px;
  }

}

.upload--input__form {
  display: flex;
  flex-direction: row;
  .form--preview {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    min-width: 320px;
    img {
      width: 100%;
      height: auto;
      border: 0;
    }
    video {
      width: 100%;
      max-height: 320px;
    }
    iframe {
      width: 100%;
      height: auto;
    }
  }
  .form{
    border-left: 1px solid #dfdfdf;
    margin-left: 12px;
    padding: 0 12px;
    width: 100%;
  }
}


.pdf--selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e2e2e2;
  padding: 10px;
  > div {
    flex: 1;
    flex-direction: row;
    &:last-child {
      text-align: right;
      justify-content: flex-end;
    }
  }
}


.upload--input {
  position: relative;
  .media__progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(255, 255, 255, 0.6);
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}