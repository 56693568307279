@import "list";
@import "item";


.widget_content--preview {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  &.film {
    grid-template-columns: 1fr;
  }
  grid-gap: 4px 4px;
  img {
    border-radius: 4px;
    width: 100%;
  }
}