
.ant-layout-header, .ant-layout-sider, .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
  background: #181717;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: darken(#181717,2%);
}
.ant-layout-sider-trigger {
  background: lighten(#181717, 2%);
}

#root {
  width: 100%;
  height: 100%;
  > div {
    width: 100%;
    height: 100%;
    &.app_loading{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.app_header {
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 4;
}

.container {
  width: 100%;
  &.container__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
  }
}

.app_loading_nflex{
  width: 100%;
  height: 100%;
}

.app_view {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.profile__avatar {
  width: 160px;
  height: 160px;
  -webkit-border-radius: 160px;
  -moz-border-radius: 160px;
  border-radius: 160px;
  background-repeat: no-repeat;
  background-size: cover;
}

[for="upload_form_properties.copyright"], [for="edit_form_properties.copyright"] {
  font-weight: 600;
}

.grid-filter {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 12px 12px;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 12px;
  max-width: 1280px;
}

.grid-filter.grid-2 {
  grid-template-columns: 1fr 1fr;
}

.grid-filter.grid-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-item {
  .grid--label {
    font-weight: 600;
    margin-bottom: 4px;
  }
}
