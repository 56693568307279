.converter {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;
  .converter_table {
    background: #FFF;
    border-radius: 12px;
    overflow: hidden;
  }
}